import { z } from 'zod';
import { suretyAccountStatusSchema } from '../../../../enums.mjs';
import { suretyAccountId, companyContactId, addressSchema, individualContactId } from '../../../../types/opaque.mjs';
import { dateTimeToISODateTimeSchema } from '../../../../types/utils.mjs';
import '../../../../types/agency/carrier-agency-relationship.types.mjs';
import '../../../../types/credit-report/company-credit-report.mjs';
import '../../../../types/credit-report/individual-credit-report.mjs';
import '../../../../types/payment/payment-template.mjs';
import '../../../../types/surety/account/surety-account.types.mjs';
import '../../../../types/surety/bond-form/submitted/submitted-surety-bond-form.types.mjs';
import '../../../../types/surety/bond-form/issued/issued-surety-bond-form.types.mjs';
import '../../../../types/surety/bond/surety-bond.types.mjs';
import '../../../../types/surety/financial-statement/surety-financial-statement.mjs';
import '../../../../types/surety/quote/draft/contract/draft-contract-surety-quote.types.mjs';
import '../../../../types/surety/quote/submitted/submitted-surety-quote.types.mjs';
import '../../../../types/surety/indemnity/indemnity-agreement.mjs';
import '../../../../types/user/user.types.mjs';
import { Get as Get$1 } from './contract/contract-surety-account-preview.dto.mjs';

var Get;
(function (Get) {
    Get.responseSchema = z.object({
        id: suretyAccountId,
        displayName: z.string().optional(),
        primaryCompanyId: companyContactId.optional(),
        status: suretyAccountStatusSchema,
        archived: z.boolean(),
        submittedAt: dateTimeToISODateTimeSchema.optional(),
        createdAt: dateTimeToISODateTimeSchema,
        companies: z.array(z.object({
            id: companyContactId,
            name: z.string(),
            address: addressSchema,
        })).default([]),
        individuals: z.array(z.object({
            id: individualContactId,
            name: z.string()
        })).default([]),
        contract: Get$1.responseSchema.optional()
    });
})(Get || (Get = {}));

export { Get };
