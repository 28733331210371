import { z } from 'zod';
import { companyContactId, addressSchema, fein, partialAddressSchema, fsr, validAddressSchema } from '../../types/opaque.mjs';
import { currencySchema, dateTimeToISODateTimeSchema } from '../../types/utils.mjs';
import '../../types/agency/carrier-agency-relationship.types.mjs';
import { Vendor } from '../../types/credit-report/company-credit-report.mjs';
import '../../types/credit-report/individual-credit-report.mjs';
import '../../types/payment/payment-template.mjs';
import '../../types/surety/account/surety-account.types.mjs';
import '../../types/surety/bond-form/submitted/submitted-surety-bond-form.types.mjs';
import '../../types/surety/bond-form/issued/issued-surety-bond-form.types.mjs';
import '../../types/surety/bond/surety-bond.types.mjs';
import '../../types/surety/financial-statement/surety-financial-statement.mjs';
import '../../types/surety/quote/draft/contract/draft-contract-surety-quote.types.mjs';
import '../../types/surety/quote/submitted/submitted-surety-quote.types.mjs';
import '../../types/surety/indemnity/indemnity-agreement.mjs';
import '../../types/user/user.types.mjs';
import { companyCreditReportFailureReasonSchema } from '../../enums.mjs';

var Get;
(function (Get) {
    Get.responseSchema = z.object({
        company: z.object({
            id: companyContactId,
            name: z.string(),
            address: addressSchema,
            fein: fein.optional(),
        }),
        creditReport: z.object({
            id: z.string().uuid(),
            success: z.boolean(),
            failureReason: companyCreditReportFailureReasonSchema.optional(),
            name: z.string().optional(),
            address: partialAddressSchema.optional(),
            fein: fein.optional(),
            judgmentBalance: currencySchema.optional(),
            lienBalance: currencySchema.optional(),
            yearsInBusinessOrCreditAge: z.number().positive().optional(),
            experianFsr: fsr.optional(),
            experianFsrFactors: Vendor.experianFSRFactorSchema.array().optional(),
            experianExecutiveElements: Vendor.experianExecutiveElementsSchema.optional(),
            experianFsrTrends: Vendor.experianFSRTrendSchema.array().optional(),
            createdAt: dateTimeToISODateTimeSchema,
            updatedAt: dateTimeToISODateTimeSchema,
        })
    });
})(Get || (Get = {}));
var Create;
(function (Create) {
    Create.requestSchema = z.object({
        companyContactId: companyContactId.optional(),
        name: z.string(),
        address: validAddressSchema,
        fein: fein.optional(),
    });
    Create.responseSchema = Get.responseSchema;
})(Create || (Create = {}));

export { Create, Get };
