import { Dtos, UserType } from "@inrev/common";
import { useContext, useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useAuthenticatedUser } from "../../../providers/AuthenticatedUserProvider";
import { GlobalErrorMessageModalContext } from "../../../providers/GlobalErrorHandlingProvider";
import { useRequest } from "../../../utils/request";
import { ApiError, SuretyEntityIdMap } from "../types";

export const useFetchFollowers = <T extends keyof SuretyEntityIdMap>(
	type: T,
	id: SuretyEntityIdMap[T],
	admin?: boolean,
) => {
	const { get } = useRequest();
	const [isFollowing, setIsFollowing] = useState<boolean | undefined>();
	const { user } = useAuthenticatedUser();

	const { data, isLoading } = useQuery({
		queryKey: ["followers", type, id],
		queryFn: async () =>
			await get<Dtos.Follower.Get.Response[]>(
				`/v2/${admin ? "admin/" : ""}surety/${type}/${id}/followers`,
			),
	});

	useEffect(() => {
		if (!!data) {
			setIsFollowing(data.some((follower) => follower.id === user.id));
		}
	}, [data]);

	return { followers: data, followersLoading: isLoading, isFollowing };
};

// export const useCurrentUserFollow = <T extends keyof SuretyEntityIdMap>(type: T, id: SuretyEntityIdMap[T]) => {
//     const { post } = useRequest();
//     const queryClient = useQueryClient();
//     const { triggerErrorModal } = useContext(GlobalErrorMessageModalContext);
//     const { user } = useAuthenticatedUser();

//     const mutation = useMutation({
//         mutationFn: async () => await post(`/v2/surety/${type}/${id}/follow`, undefined, "none"),
//         onMutate: () => {
//             queryClient.setQueryData(['followers', type, id], (oldData: Dtos.Follower.Get.Response[] | undefined) => {
//                 if (!!oldData) {
//                     return [...oldData, { authzId: user.authzId, firstName: user.firstName, lastName: user.lastName, email: user.email }];
//                 } else {
//                     return [{ authzId: user.authzId, firstName: user.firstName, lastName: user.lastName, email: user.email }];
//                 }
//             });
//         },
//         onSuccess: () => {
//             queryClient.invalidateQueries(['followers', type, id]);
//         },
//         onError:(error: ApiError) => {
//             console.error(error);
//             triggerErrorModal(error);
//             queryClient.setQueryData(['followers', type, id], (oldData: Dtos.Follower.Get.Response[] | undefined) => {
//                 if (!!oldData) {
//                     return oldData.filter(follower => follower.authzId !== user.authzId);
//                 } else {
//                     return [];
//                 }
//             });
//         }
//     });

//     return { currentUserFollow: mutation.mutate, currentUserFollowLoading: mutation.isLoading };
// }

// export const useCurrentUserUnfollow = <T extends keyof SuretyEntityIdMap>(type: T, id: SuretyEntityIdMap[T]) => {
//     const { post } = useRequest();
//     const queryClient = useQueryClient();
//     const { triggerErrorModal } = useContext(GlobalErrorMessageModalContext);
//     const { user } = useAuthenticatedUser();

//     const mutation = useMutation({
//         mutationFn: async () => await post(`/v2/surety/${type}/${id}/unfollow`, undefined, "none"),
//         onMutate: () => {
//             queryClient.setQueryData(['followers', type, id], (oldData: Dtos.Follower.Get.Response[] | undefined) => {
//                 if (!!oldData) {
//                     return oldData.filter(follower => follower.authzId !== user.authzId);
//                 } else {
//                     return [];
//                 }
//             });
//         },
//         onSuccess: () => {
//             queryClient.invalidateQueries(['followers', type, id]);
//         },
//         onError:(error: ApiError) => {
//             console.error(error);
//             triggerErrorModal(error);
//             queryClient.setQueryData(['followers', type, id], (oldData: Dtos.Follower.Get.Response[] | undefined) => {
//                 if (!!oldData) {
//                     return [...oldData, { authzId: user.authzId, fullName: formatName(user), email: user.email }];
//                 } else {
//                     return [{ authzId: user.authzId, fullName: formatName(user), email: user.email }];
//                 }
//             });
//         }
//     });

//     return { currentUserUnfollow: mutation.mutate, currentUserUnfollowLoading: mutation.isLoading };
// }

export const useCreateAgentFollower = <T extends keyof SuretyEntityIdMap>(
	type: T,
	id: SuretyEntityIdMap[T],
	admin?: boolean,
) => {
	const { post } = useRequest();
	const queryClient = useQueryClient();
	const { triggerErrorModal } = useContext(GlobalErrorMessageModalContext);
	const { user } = useAuthenticatedUser();

	const mutation = useMutation({
		mutationFn: async (agent?: Dtos.Agent.Preview.Get.Response) =>
			await post(
				`/v2/${admin ? "admin/" : ""}surety/${type}/${id}/followers/${agent?.id ?? user.id}`,
				undefined,
				"none",
			),
		onMutate: (agent?: Dtos.Agent.Preview.Get.Response) => {
			const newFollower: Dtos.Follower.Get.Response = agent
				? { ...agent, type: UserType.agent }
				: user;
			queryClient.setQueryData(
				["followers", type, id],
				(oldData: Dtos.Follower.Get.Response[] | undefined) => {
					if (!!oldData) {
						return [...oldData, newFollower];
					}
					return [newFollower];
				},
			);

			return newFollower;
		},
		onSuccess: () => {
			queryClient.invalidateQueries(["followers", type, id]);
		},
		onError: (error: ApiError, _variables, newFollower) => {
			console.error(error);
			triggerErrorModal(error);
			if (!!newFollower) {
				queryClient.setQueryData(
					["followers", type, id],
					(oldData: Dtos.Follower.Get.Response[] | undefined) => {
						if (!!oldData) {
							return oldData.filter((follower) => follower.id !== newFollower.id);
						}
						return [];
					},
				);
			}
		},
	});

	return { createAgentFollower: mutation.mutate, createAgentFollowerLoading: mutation.isLoading };
};

export const useRemoveAgentFollower = <T extends keyof SuretyEntityIdMap>(
	type: T,
	id: SuretyEntityIdMap[T],
	admin?: boolean,
) => {
	const { _delete } = useRequest();
	const queryClient = useQueryClient();
	const { triggerErrorModal } = useContext(GlobalErrorMessageModalContext);
	const { user } = useAuthenticatedUser();

	const mutation = useMutation({
		mutationFn: async (agent?: Dtos.Agent.Preview.Get.Response) =>
			await _delete(
				`/v2/${admin ? "admin/" : ""}surety/${type}/${id}/followers/${agent?.id ?? user.id}`,
				undefined,
				"none",
			),
		onMutate: (agent?: Dtos.Agent.Preview.Get.Response) => {
			const followerToRemove: Dtos.Follower.Get.Response = agent
				? { ...agent, type: UserType.agent }
				: user;
			queryClient.setQueryData(
				["followers", type, id],
				(oldData: Dtos.Follower.Get.Response[] | undefined) => {
					if (!!oldData) {
						return oldData.filter((follower) => follower.id !== followerToRemove.id);
					}
					return [];
				},
			);

			return followerToRemove;
		},
		onSuccess: () => {
			queryClient.invalidateQueries(["followers", type, id]);
		},
		onError: (error: ApiError, _variables, followerToRemove) => {
			console.error(error);
			triggerErrorModal(error);
			if (!!followerToRemove) {
				queryClient.setQueryData(
					["followers", type, id],
					(oldData: Dtos.Follower.Get.Response[] | undefined) => {
						if (!!oldData) {
							return [...oldData, followerToRemove];
						}
						return [followerToRemove];
					},
				);
			}
		},
	});

	return { removeAgentFollower: mutation.mutate, removeAgentFollowerLoading: mutation.isLoading };
};

export const useFollowers = <T extends keyof SuretyEntityIdMap>(
	type: T,
	id: SuretyEntityIdMap[T],
	admin?: boolean,
) => {
	const fetchFollowersProps = useFetchFollowers(type, id, admin);
	const createAgentFollowerProps = useCreateAgentFollower(type, id, admin);
	const removeAgentFollowerProps = useRemoveAgentFollower(type, id, admin);

	return {
		...fetchFollowersProps,
		...createAgentFollowerProps,
		...removeAgentFollowerProps,
	};
};
