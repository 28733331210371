import { z } from 'zod';
import { contractSuretyTypeSchema, suretyBondFormTypeSchema } from '../../../enums.mjs';
import { suretyBondFormTemplateId } from '../../../types/opaque.mjs';
import '../../../types/utils.mjs';
import '../../../types/agency/carrier-agency-relationship.types.mjs';
import '../../../types/credit-report/company-credit-report.mjs';
import '../../../types/credit-report/individual-credit-report.mjs';
import '../../../types/payment/payment-template.mjs';
import '../../../types/surety/account/surety-account.types.mjs';
import '../../../types/surety/bond-form/submitted/submitted-surety-bond-form.types.mjs';
import '../../../types/surety/bond-form/issued/issued-surety-bond-form.types.mjs';
import '../../../types/surety/bond/surety-bond.types.mjs';
import '../../../types/surety/financial-statement/surety-financial-statement.mjs';
import '../../../types/surety/quote/draft/contract/draft-contract-surety-quote.types.mjs';
import '../../../types/surety/quote/submitted/submitted-surety-quote.types.mjs';
import '../../../types/surety/indemnity/indemnity-agreement.mjs';
import '../../../types/user/user.types.mjs';

var GetAll;
(function (GetAll) {
    (function (Request) {
        Request.schema = z.discriminatedUnion("suretyType", [
            z.object({ suretyType: z.literal('contract'), contractSuretyType: contractSuretyTypeSchema })
        ]);
    })(GetAll.Request || (GetAll.Request = {}));
    (function (Response) {
        Response.schema = z.array(z.object({
            id: suretyBondFormTemplateId,
            name: z.string(),
            type: suretyBondFormTypeSchema,
            tags: z.array(z.string())
        }));
    })(GetAll.Response || (GetAll.Response = {}));
})(GetAll || (GetAll = {}));
var GetPreview;
(function (GetPreview) {
    (function (Response) {
        Response.schema = z.object({
            url: z.string()
        });
    })(GetPreview.Response || (GetPreview.Response = {}));
})(GetPreview || (GetPreview = {}));

export { GetAll, GetPreview };
