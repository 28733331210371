import { useAuth0 } from "@auth0/auth0-react";
import { UserType } from "@inrev/common";
import { AdminApp } from "./AdminApp";
import { AgentApp } from "./AgentApp";
import { useAuthenticatedUser } from "./providers/AuthenticatedUserProvider";
import { CompleteAccountSetupView } from "./views/agent/setup/CompleteAccountSetupView";

export const AppRouter = () => {
	const { loginWithRedirect } = useAuth0();
	const { user } = useAuthenticatedUser();

	if (user.type === UserType.admin) {
		return <AdminApp />;
	}
	if (user.type === UserType.agent) {
		if (user.setupComplete) return <AgentApp />;
		return <CompleteAccountSetupView />;
	}
	loginWithRedirect();
};
