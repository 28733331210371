import { type ReactNode, useEffect } from "react";
import { useAuthenticatedUser } from "./AuthenticatedUserProvider";

export const PendoProvider = ({ children }: { children: ReactNode }) => {
	const { user } = useAuthenticatedUser();
	useEffect(() => {
		if (user.setupComplete && user.type === "agent") {
			pendo.initialize({
				visitor: {
					id: user.id,
					email: user.email,
					firstName: user.firstName,
					lastName: user.lastName,
					role: user.role,
				},
				account: {
					id: user.agencyId,
				},
			});
		}
	}, [user]);

	return <>{children}</>;
};
