import { WithAuthenticationRequiredOptions, withAuthenticationRequired } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthenticatedUser } from "../providers/AuthenticatedUserProvider";

type AuthGuardProps = WithAuthenticationRequiredOptions & {
	component: React.ComponentType<object>;
};

export const AuthGuard = ({ component, ...args }: AuthGuardProps) => {
	const Component = withAuthenticationRequired(component, args);
	return <Component />;
};

type RBACAuthGuardProps = WithAuthenticationRequiredOptions & {
	roles: string[];
	rbacRedirectTo: string;
	component: React.ComponentType<object>;
};

export const RBACAuthGuard = ({
	roles,
	rbacRedirectTo,
	component,
	...args
}: RBACAuthGuardProps) => {
	const navigate = useNavigate();
	const { user } = useAuthenticatedUser();

	useEffect(() => {
		if (!roles.some((role) => user.role === role)) {
			navigate(rbacRedirectTo);
		}
	}, [user]);

	const Component = withAuthenticationRequired(component, args);

	return <Component />;
};
