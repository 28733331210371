import { z } from 'zod';
import { Get as Get$1 } from '../draft-surety-quote.mixins.dto.mjs';
import { draftSchema, dataSchema } from '../../../../../types/surety/quote/draft/contract/draft-contract-surety-quote.types.mjs';
import { SuretyType } from '../../../../../enums.mjs';
import { suretyAccountId, companyContactId } from '../../../../../types/opaque.mjs';
import '../../../../../types/utils.mjs';
import '../../../../../types/agency/carrier-agency-relationship.types.mjs';
import '../../../../../types/credit-report/company-credit-report.mjs';
import '../../../../../types/credit-report/individual-credit-report.mjs';
import '../../../../../types/payment/payment-template.mjs';
import '../../../../../types/surety/account/surety-account.types.mjs';
import '../../../../../types/surety/bond-form/submitted/submitted-surety-bond-form.types.mjs';
import '../../../../../types/surety/bond-form/issued/issued-surety-bond-form.types.mjs';
import '../../../../../types/surety/bond/surety-bond.types.mjs';
import '../../../../../types/surety/financial-statement/surety-financial-statement.mjs';
import '../../../../../types/surety/quote/submitted/submitted-surety-quote.types.mjs';
import '../../../../../types/surety/indemnity/indemnity-agreement.mjs';
import '../../../../../types/user/user.types.mjs';

var Get;
(function (Get) {
    Get.responseSchema = Get$1.Response.baseSchema.extend({
        draft: draftSchema
    });
})(Get || (Get = {}));
var Create;
(function (Create) {
    Create.requestSchema = z.object({
        suretyType: z.literal(SuretyType.contract),
    }).and(z.union([
        z.object({
            accountId: suretyAccountId,
            principalCompanyId: companyContactId
        }),
        z.object({
            accountId: z.undefined(),
            principalCompanyId: z.undefined()
        })
    ]));
    Create.responseSchema = Get.responseSchema;
})(Create || (Create = {}));
var Update;
(function (Update) {
    Update.requestSchema = dataSchema;
    Update.responseSchema = Get.responseSchema;
})(Update || (Update = {}));

export { Create, Get, Update };
