import { z } from 'zod';
import { companyContactId } from '../../../../../types/opaque.mjs';
import { percentSchema, rateSchema, currencySchema } from '../../../../../types/utils.mjs';
import '../../../../../types/agency/carrier-agency-relationship.types.mjs';
import '../../../../../types/credit-report/company-credit-report.mjs';
import '../../../../../types/credit-report/individual-credit-report.mjs';
import '../../../../../types/payment/payment-template.mjs';
import '../../../../../types/surety/account/surety-account.types.mjs';
import '../../../../../types/surety/bond-form/submitted/submitted-surety-bond-form.types.mjs';
import '../../../../../types/surety/bond-form/issued/issued-surety-bond-form.types.mjs';
import '../../../../../types/surety/bond/surety-bond.types.mjs';
import '../../../../../types/surety/financial-statement/surety-financial-statement.mjs';
import '../../../../../types/surety/quote/draft/contract/draft-contract-surety-quote.types.mjs';
import '../../../../../types/surety/quote/submitted/submitted-surety-quote.types.mjs';
import '../../../../../types/surety/indemnity/indemnity-agreement.mjs';
import '../../../../../types/user/user.types.mjs';
import { Get as Get$1 } from '../submitted-surety-quote-mixins.mjs';
import { Get as Get$2 } from '../../../account/submitted/contract/submitted-contract-surety-account.dto.mjs';
import { SuretyType } from '../../../../../enums.mjs';

var Get;
(function (Get) {
    (function (Response) {
        Response.baseShape = {
            suretyType: z.literal(SuretyType.contract),
            principal: z.object({
                companyContactId: companyContactId,
                name: z.string(),
            }),
        };
        Response.baseSchema = Get$1.Response.baseSchema.extend(Response.baseShape);
        Response.underwrittenSchemaShape = {
            ...Get$1.Response.underwrittenSchemaShape,
            bondIssuance: Get$1.Response.underwrittenSchemaShape.bondIssuance.extend({
                indemnityAgreement: Get$2.Response.IndemnityAgreement.schema
            })
        };
        Response.pendingStatusShape = {
            ...Get$1.Response.pendingStatusShape,
            bondIssuance: Get$1.Response.pendingStatusShape.bondIssuance.extend({
                indemnityAgreement: Response.underwrittenSchemaShape.bondIssuance.shape.indemnityAgreement
            })
        };
        (function (Admin) {
            Admin.reviewStatusShape = {
                agencyCommissionPercentage: percentSchema.optional(),
                rate: rateSchema.optional(),
                premium: currencySchema.optional(),
            };
        })(Response.Admin || (Response.Admin = {}));
    })(Get.Response || (Get.Response = {}));
})(Get || (Get = {}));

export { Get };
