import { z } from 'zod';
import { Create as Create$1 } from './contract/contract-surety-bond-issuance.dto.mjs';
import * as contractSuretyBondIssuance_dto from './contract/contract-surety-bond-issuance.dto.mjs';
export { contractSuretyBondIssuance_dto as Contract };
import '../../../types/opaque.mjs';
import { zswitch } from '../../../types/utils.mjs';
import '../../../types/agency/carrier-agency-relationship.types.mjs';
import '../../../types/credit-report/company-credit-report.mjs';
import '../../../types/credit-report/individual-credit-report.mjs';
import '../../../types/payment/payment-template.mjs';
import '../../../types/surety/account/surety-account.types.mjs';
import '../../../types/surety/bond-form/submitted/submitted-surety-bond-form.types.mjs';
import '../../../types/surety/bond-form/issued/issued-surety-bond-form.types.mjs';
import '../../../types/surety/bond/surety-bond.types.mjs';
import '../../../types/surety/financial-statement/surety-financial-statement.mjs';
import '../../../types/surety/quote/draft/contract/draft-contract-surety-quote.types.mjs';
import '../../../types/surety/quote/submitted/submitted-surety-quote.types.mjs';
import '../../../types/surety/indemnity/indemnity-agreement.mjs';
import '../../../types/user/user.types.mjs';

var Create;
(function (Create) {
    (function (Request) {
        Request.schema = zswitch(input => {
            switch (input.suretyType) {
                case "contract": return Create$1.Request.schema;
                default: return z.never();
            }
        });
    })(Create.Request || (Create.Request = {}));
})(Create || (Create = {}));

export { Create };
