import { z } from 'zod';
import { Data } from '../../../../types/surety/bond-form/submitted/submitted-surety-bond-form.types.mjs';
import { suretyBondFormId } from '../../../../types/opaque.mjs';
import '../../../../types/utils.mjs';
import '../../../../types/agency/carrier-agency-relationship.types.mjs';
import '../../../../types/credit-report/company-credit-report.mjs';
import '../../../../types/credit-report/individual-credit-report.mjs';
import '../../../../types/payment/payment-template.mjs';
import '../../../../types/surety/account/surety-account.types.mjs';
import '../../../../types/surety/bond-form/issued/issued-surety-bond-form.types.mjs';
import '../../../../types/surety/bond/surety-bond.types.mjs';
import '../../../../types/surety/financial-statement/surety-financial-statement.mjs';
import '../../../../types/surety/quote/draft/contract/draft-contract-surety-quote.types.mjs';
import '../../../../types/surety/quote/submitted/submitted-surety-quote.types.mjs';
import '../../../../types/surety/indemnity/indemnity-agreement.mjs';
import '../../../../types/user/user.types.mjs';
import { suretyBondIssuanceMethodSchema } from '../../../../enums.mjs';

var Get;
(function (Get) {
    (function (Response) {
        Response.schema = Data.schema.and(z.object({
            id: suretyBondFormId,
            issuanceMethod: suretyBondIssuanceMethodSchema.optional(),
            approvedBeforeIssuance: z.boolean(),
        })).and(z.discriminatedUnion("source", [
            z.object({
                source: z.literal("library"),
                template: z.string()
            }),
            z.object({ source: z.literal("upload") })
        ]));
    })(Get.Response || (Get.Response = {}));
})(Get || (Get = {}));

export { Get };
