import { formatToDollar } from "@inrev/common";
import { Underwriting } from "@inrev/common/lib/esm/types/surety/account/surety-account.types";
import { DonutChartHero } from "../../../components/charts/DonutChart";
import { LineChartGenerator } from "../../../components/charts/LineChart";
import { HeaderActions } from "../../../components/layout/HeaderActions";
import { LoadingModal } from "../../../components/layout/LoadingModal";
import { KPICard } from "../../../components/ui/KPICard";
import { Separator } from "../../../components/ui/Separator";
import { StackedCard } from "../../../components/ui/StackedCard";
import { useGetAgencyStats } from "../../../domain/agent/dashboard/api";
import { useAuthenticatedUser } from "../../../providers/AuthenticatedUserProvider";
import { formatEmpty } from "../../../utils";

export const DashboardView = () => {
	const { user } = useAuthenticatedUser();
	const { agencyStats, agencyStatsIsLoading } = useGetAgencyStats();

	if (agencyStatsIsLoading || agencyStats === undefined) return <LoadingModal />;
	return (
		<div className="w-full h-full">
			<div className="w-full h-full flex flex-col min-w-[500px]">
				<div className="flex justify-center w-full px-[60px] pt-[35px] pb-[15px]">
					<div className="w-full max-w-[1050px] flex gap-[50px] items-start">
						<div className="hidden sm:flex flex-1 gap-x-[14px] gap-y-[5px] items-center flex-wrap">
							<div className="text-[18px] md:text-[22px] font-semibold text-gray-900 truncate text-wrap min-w-[50px]">
								{agencyStats.agencyDisplayName}
							</div>
							<div className="flex gap-[14px] shrink-0 items-center">
								<Separator orientation="vertical" className="h-[18px] bg-gray-300" />
								<div className="text-[14px] font-normal text-gray-500 truncate">
									{user.firstName} {user.lastName}
								</div>
							</div>
						</div>
						<HeaderActions
							includeActionButtons={true}
							className="w-full justify-between sm:w-fit sm:justify-normal"
						/>
					</div>
				</div>
				<div className="flex justify-center pt-[20px] px-[60px] pb-[100px] flex-1 overflow-y-auto">
					<div className="flex flex-col space-y-[35px] min-h-fit w-full max-w-[1050px]">
						<div className="flex flex-wrap gap-7 shrink-0">
							<KPICard
								title="Bonds Requested"
								value={agencyStats.quoteCountTotal}
								className="flex-1 min-w-[175px]"
							/>
							<KPICard
								title="Bonds Issued"
								value={agencyStats.bondCountTotal}
								className="flex-1 min-w-[175px]"
							/>
							<KPICard
								title="Commission"
								value={formatToDollar(agencyStats.commissionTotal)}
								className="flex-1 min-w-[310px]"
							/>
						</div>
						<Separator orientation="horizontal" />
						<div className="flex flex-wrap gap-7 h-[240px]">
							<StackedCard
								className="flex flex-col px-[25px] py-[15px] h-[250px] gap-5"
								wrapperClassName="flex-1 min-w-[265px]"
							>
								<div className="text-sm font-medium text-gray-800">Request Trend</div>
								{LineChartGenerator({
									category: "Requests",
									data: agencyStats.quoteCount3Month.map((data) => ({
										month: data.month,
										Requests: data.count,
									})),
									valueFormatter: (number: number) => number.toString(),
									index: "month",
									yAxisWidth: 30,
								})}
							</StackedCard>
							<StackedCard
								className="flex flex-col px-[25px] py-[15px] h-[250px] gap-5"
								wrapperClassName="flex-1 min-w-[265px]"
							>
								<div className="text-sm font-medium text-gray-800">Earnings Trend</div>
								{LineChartGenerator({
									category: "Earnings",
									data: agencyStats.commissionTotal3Month.map((data) => ({
										month: data.month,
										Earnings: data.total,
									})),
									valueFormatter: (value) => formatEmpty(formatToDollar(value, true)),
									index: "month",
									yAxisWidth: 60,
								})}
							</StackedCard>
							<StackedCard
								className="flex flex-col px-[25px] pt-[15px] pb-[40px] h-[250px] gap-[45px]"
								wrapperClassName="flex-1 min-w-[265px]"
							>
								<div className="flex-1 text-sm font-medium text-gray-800">
									Average Account Score
								</div>
								{DonutChartHero({
									label: agencyStats.averageAccountLetterGrade.average,
									data: Object.keys(agencyStats.averageAccountLetterGrade.count).every(
										(key) =>
											agencyStats.averageAccountLetterGrade.count[
												key as Underwriting.LetterGrade
											] === 0,
									)
										? []
										: Object.keys(agencyStats.averageAccountLetterGrade.count).map((key) => ({
												name: key,
												value: agencyStats.averageAccountLetterGrade.count[
													key as Underwriting.LetterGrade
												] as number,
											})),
									noDataText: "No Accounts Submitted",
								})}
							</StackedCard>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
