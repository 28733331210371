import { z } from 'zod';
import { auth0UserRoleSchema } from '../../enums.mjs';
import { userId, email, firstName, lastName } from '../../types/opaque.mjs';
import { dateTimeToISODateTimeSchema } from '../../types/utils.mjs';
import '../../types/agency/carrier-agency-relationship.types.mjs';
import '../../types/credit-report/company-credit-report.mjs';
import '../../types/credit-report/individual-credit-report.mjs';
import '../../types/payment/payment-template.mjs';
import '../../types/surety/account/surety-account.types.mjs';
import '../../types/surety/bond-form/submitted/submitted-surety-bond-form.types.mjs';
import '../../types/surety/bond-form/issued/issued-surety-bond-form.types.mjs';
import '../../types/surety/bond/surety-bond.types.mjs';
import '../../types/surety/financial-statement/surety-financial-statement.mjs';
import '../../types/surety/quote/draft/contract/draft-contract-surety-quote.types.mjs';
import '../../types/surety/quote/submitted/submitted-surety-quote.types.mjs';
import '../../types/surety/indemnity/indemnity-agreement.mjs';
import '../../types/user/user.types.mjs';

var Get;
(function (Get) {
    Get.responseSchema = z.object({
        id: userId,
        email: email,
        userRoles: z.array(auth0UserRoleSchema).min(1),
        invitationPending: z.boolean(),
        invitedAt: dateTimeToISODateTimeSchema.optional(),
        firstName: firstName.optional(),
        lastName: lastName.optional(),
    });
})(Get || (Get = {}));

export { Get };
