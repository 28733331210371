import { z } from 'zod';
import '../../../../../types/opaque.mjs';
import { suretyAccountBondingLineSchema, zswitch } from '../../../../../types/utils.mjs';
import '../../../../../types/agency/carrier-agency-relationship.types.mjs';
import '../../../../../types/credit-report/company-credit-report.mjs';
import '../../../../../types/credit-report/individual-credit-report.mjs';
import '../../../../../types/payment/payment-template.mjs';
import '../../../../../types/surety/account/surety-account.types.mjs';
import '../../../../../types/surety/bond-form/submitted/submitted-surety-bond-form.types.mjs';
import '../../../../../types/surety/bond-form/issued/issued-surety-bond-form.types.mjs';
import '../../../../../types/surety/bond/surety-bond.types.mjs';
import '../../../../../types/surety/financial-statement/surety-financial-statement.mjs';
import '../../../../../types/surety/quote/draft/contract/draft-contract-surety-quote.types.mjs';
import '../../../../../types/surety/quote/submitted/submitted-surety-quote.types.mjs';
import '../../../../../types/surety/indemnity/indemnity-agreement.mjs';
import '../../../../../types/user/user.types.mjs';
import { Metadata } from '../../../../../types/surety/account/contract/contract-surety-account.types.mjs';
import { Get as Get$1 } from '../admin-surety-account-mixins.dto.mjs';
import { Get as Get$2, Update as Update$1 } from '../../carrier-bonding-line/admin-surety-account-bonding-line.dto.mjs';
import { SuretyType, SuretyAccountUnderwritingStatus, contractSuretyAccountUnderwritingModelSizeSchema, ExposureSize } from '../../../../../enums.mjs';
import { Submit as Submit$1 } from '../../../../surety/account/contract/contract-surety-account.dto.mjs';

var Get;
(function (Get) {
    (function (Response) {
        Response.schema = Metadata.schema.extend({
            underwriting: Get$1.Response.Underwriting.schema.extend({
                input: z.object({
                    financials: z.object({
                        profitableYtd: z.boolean().optional(),
                    })
                })
            }),
            carrierBondingLines: z.array(Get$2.Response.schema),
            indemnity: Get$1.Response.Indemnity.schema
        });
    })(Get.Response || (Get.Response = {}));
})(Get || (Get = {}));
var Update;
(function (Update) {
    (function (Status) {
        (function (Request) {
            const baseSchema = z.object({
                suretyType: z.literal(SuretyType.contract),
                score: z.coerce.number().min(0).max(5),
                bondingLine: suretyAccountBondingLineSchema
            });
            Request.schema = zswitch(input => {
                switch (input.status) {
                    case SuretyAccountUnderwritingStatus.approved: return baseSchema.extend({
                        status: z.literal(SuretyAccountUnderwritingStatus.approved),
                        carrierBondingLines: z.array(Update$1.Request.schema),
                        reviewNote: z.string().nullish()
                    });
                    case SuretyAccountUnderwritingStatus.declined: return baseSchema.extend({
                        status: z.literal(SuretyAccountUnderwritingStatus.declined),
                        reviewNote: z.string().nullish()
                    });
                    default: return z.never();
                }
            });
        })(Status.Request || (Status.Request = {}));
    })(Update.Status || (Update.Status = {}));
})(Update || (Update = {}));
var Submit;
(function (Submit) {
    (function (Request) {
        (function (Existing) {
            let Data;
            (function (Data) {
                Data.buildDataValidationSchema = (formSchema) => {
                    return zswitch(input => {
                        const parsedModelSize = contractSuretyAccountUnderwritingModelSizeSchema.safeParse(input.details?.exposureSize);
                        if (!parsedModelSize.success) {
                            return z.never();
                        }
                        const detailsSchema = Submit$1.Request.Existing.Data.buildDetailsDataValidationSchema(formSchema.details);
                        switch (parsedModelSize.data) {
                            case ExposureSize.small:
                                return z.object({
                                    details: detailsSchema,
                                    history: Submit$1.Request.New.Data.History.Small.schema,
                                    financials: Submit$1.Request.New.Data.Financials.Small.schema
                                });
                            case ExposureSize.medium:
                                return z.object({
                                    details: detailsSchema,
                                    history: Submit$1.Request.New.Data.History.Medium.schema,
                                    financials: Submit$1.Request.New.Data.Financials.Medium.schema
                                });
                            case ExposureSize.large:
                                return z.object({
                                    details: detailsSchema,
                                    history: Submit$1.Request.New.Data.History.Large.schema,
                                    financials: Submit$1.Request.New.Data.Financials.Large.schema
                                });
                            default:
                                return z.never();
                        }
                    });
                };
            })(Data = Existing.Data || (Existing.Data = {}));
            Existing.buildDtoValidationSchema = (formSchema) => z.object({
                suretyType: z.literal(SuretyType.contract),
                data: Data.buildDataValidationSchema(formSchema),
            });
        })(Request.Existing || (Request.Existing = {}));
    })(Submit.Request || (Submit.Request = {}));
    (function (Response) {
        Response.schema = Get.Response.schema;
    })(Submit.Response || (Submit.Response = {}));
})(Submit || (Submit = {}));

export { Get, Submit, Update };
