import * as Sentry from "@sentry/react";
import { type ReactNode, useEffect } from "react";
import { useAuthenticatedUser } from "./AuthenticatedUserProvider";

export const SentryProvider = ({ children }: { children: ReactNode }) => {
	const { user } = useAuthenticatedUser();
	useEffect(() => {
		Sentry.setUser(user);
	}, [user]);

	return <>{children}</>;
};
