import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import { AppRouter } from "./AppRouter";
import { AuthProvider } from "./providers/AuthProvider";
import { AuthenticatedUserProvider } from "./providers/AuthenticatedUserProvider";
import { GlobalErrorMessageModalProvider } from "./providers/GlobalErrorHandlingProvider";
import { PendoProvider } from "./providers/PendoProvider";
import { SentryProvider } from "./providers/SentryProvider";

function App() {
	const queryClient = new QueryClient({
		defaultOptions: {
			queries: {
				staleTime: 60000 * 5,
				cacheTime: 60000 * 5,
				refetchOnWindowFocus: false,
				refetchIntervalInBackground: false,
			},
		},
	});

	return (
		<BrowserRouter>
			<GlobalErrorMessageModalProvider>
				<AuthProvider>
					<AuthenticatedUserProvider>
						<SentryProvider>
							<PendoProvider>
								<QueryClientProvider client={queryClient}>
									<AppRouter />
								</QueryClientProvider>
							</PendoProvider>
						</SentryProvider>
					</AuthenticatedUserProvider>
				</AuthProvider>
			</GlobalErrorMessageModalProvider>
		</BrowserRouter>
	);
}

export default Sentry.withProfiler(App);
