import { CompanyContactId, Dtos, SuretyAccountId, SuretyType, Types } from "@inrev/common";
import { useContext } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDebouncedCallback } from "use-debounce";
import { useGetFileBuffer } from "../../../api";
import { GlobalErrorMessageModalContext } from "../../../providers/GlobalErrorHandlingProvider";
import { useRequest } from "../../../utils/request";
import { ApiError } from "../../shared/types";
import type { BondRequest } from "../request/types";
import { SuretyAccount, SuretyAccountPreview } from "./types";

export const useCreateSuretyAccountDraft = () => {
	const queryClient = useQueryClient();
	const { post } = useRequest();
	const { triggerErrorModal } = useContext(GlobalErrorMessageModalContext);

	const mutation = useMutation({
		mutationKey: "createSuretyAccount",
		mutationFn: async (suretyType: SuretyType) =>
			await post<SuretyAccount>("/v2/surety/accounts/draft", { suretyType }),
		onSuccess: (newAccount) => {
			queryClient.setQueryData(["suretyAccounts", newAccount.id], newAccount);
			queryClient.refetchQueries(["accountPreviews"]);
		},
		onError: (error: ApiError) => {
			console.error(error);
			triggerErrorModal(error);
		},
	});

	return { createSuretyAccount: mutation.mutate, createSuretyAccountIsLoading: mutation.isLoading };
};

export const useSubmitSuretyAccount = (accountId: string, suretyType: SuretyType) => {
	const { post } = useRequest();
	const queryClient = useQueryClient();
	const { triggerErrorModal } = useContext(GlobalErrorMessageModalContext);

	const mutation = useMutation({
		mutationFn: async (data: Dtos.SuretyAccount.Submit.Request.New["data"]) =>
			post<SuretyAccount>(`/v2/surety/accounts/draft/${accountId}`, { suretyType, data }),
		onSuccess: (updatedAccount) => {
			queryClient.setQueryData(["suretyAccounts", updatedAccount.id], updatedAccount);
			queryClient.refetchQueries(["accountPreviews"]);
		},
		onError: (error: ApiError) => {
			console.error(error);
			triggerErrorModal(error);
		},
	});

	return { submitSuretyAccount: mutation.mutate, submitSuretyAccountIsLoading: mutation.isLoading };
};

export const useFetchSuretyAccount = (id: string) => {
	const { get } = useRequest();
	const { triggerErrorModal } = useContext(GlobalErrorMessageModalContext);

	const { data, error, isLoading } = useQuery({
		queryKey: ["suretyAccounts", id],
		queryFn: async () => {
			const response = await get<Dtos.SuretyAccount.Get.Response>(`/v2/surety/accounts/${id}`);
			if (response.status !== "draft" && response.contract === undefined)
				throw new Error(
					`Error fetching surety account ${id}: contract is undefined in response dto`,
				);
			return response as SuretyAccount;
		},
		retry: false,
		onError: (error: ApiError) => {
			console.error(error);
			triggerErrorModal(error);
		},
	});

	return { suretyAccount: data, suretyAccountError: error, suretyAccountIsLoading: isLoading };
};

export const useAwaitedUpdateSuretyAccountDraft = (id: string) => {
	const { patch } = useRequest();
	const queryClient = useQueryClient();

	const mutation = useMutation({
		mutationFn: async (
			data: Types.SuretyAccount.Draft.Contract.Data,
		): Promise<Dtos.SuretyAccount.Draft.Contract.Update.Response> => {
			return await patch<Dtos.SuretyAccount.Draft.Contract.Update.Response>(
				`/v2/surety/accounts/draft/${id}/sync`,
				data,
			);
		},
		onSuccess: (result) => {
			queryClient.setQueryData<SuretyAccount>(["suretyAccounts", id], (old) => {
				if (!old) throw new Error();
				return { ...old, ...result };
			});
			queryClient.invalidateQueries(["accountPreviews"]);
		},
	});

	const debouncedMutate = useDebouncedCallback(mutation.mutate, 50); // debounced to let form fields move focus to next element

	return {
		awaitUpdateSuretyAccountDraft: debouncedMutate,
		awaitUpdateSuretyAccountDraftIsLoading: mutation.isLoading,
	};
};

export const useUpdateSuretyAccountDraft = (id: string) => {
	const { patch } = useRequest();
	const queryClient = useQueryClient();

	const mutation = useMutation({
		mutationFn: async (draftData: Types.SuretyAccount.Draft.Contract.Data): Promise<void> => {
			await patch(`/v2/surety/accounts/draft/${id}`, draftData, "none");
		},
		onSuccess: () => {
			queryClient.invalidateQueries(["accountPreviews"]);
		},
		onError: (error: ApiError) => {
			console.error(error);
		},
	});

	return {
		updateSuretyAccountDraft: mutation.mutate,
		updateSuretyAccountDraftIsLoading: mutation.isLoading,
	};
};

export const useCreateAccountIndemnityAgreement = () => {
	const { post } = useRequest();
	const queryClient = useQueryClient();
	const { triggerErrorModal } = useContext(GlobalErrorMessageModalContext);

	const mutation = useMutation({
		mutationFn: async (args: {
			accountId: string;
			data: Dtos.IndemnityAgreement.Create.Request;
		}) => {
			await post(`/v2/surety/accounts/${args.accountId}/indemnity`, args.data);
			queryClient.refetchQueries(["suretyAccounts", args.accountId]);
		},
		onSuccess: (_data, variables) => {
			queryClient.invalidateQueries({
				queryKey: ["bondRequests"],
				predicate: (query) => {
					if (!query.state.data) return false;
					const data = query.state.data as BondRequest;
					return data.status !== "draft" && data.account.id === variables.accountId;
				},
			});
		},
		onError: (error: ApiError) => {
			console.error(error);
			triggerErrorModal(error);
		},
	});

	return {
		createAccountIndemnityAgreement: mutation.mutateAsync,
		createAccountIndemnityAgreementIsLoading: mutation.isLoading,
	};
};

export const useGetAccountIndemnityAgreementFileUrl = (
	accountId: SuretyAccountId,
	suretyType: SuretyType,
	asDownload?: boolean,
) => {
	const { get } = useRequest();
	const { triggerErrorModal } = useContext(GlobalErrorMessageModalContext);

	const { data, error, isLoading, refetch } = useQuery({
		queryKey: ["indemnityAgreementFileUrl", accountId, suretyType, asDownload],
		queryFn: async () =>
			await get(
				`/v2/surety/accounts/${accountId}/indemnity/${suretyType}?download=${asDownload ?? false}`,
				undefined,
				"text",
			),
		onSuccess: (data) => {
			if (asDownload) {
				const a: HTMLAnchorElement = document.createElement("a");
				a.href = data;
				a.download = data.slice(data.lastIndexOf("/") + 1);
				document.body.appendChild(a);
				a.click();
				document.body.removeChild(a);
			}
		},
		onError: (error: ApiError) => {
			console.error(error);
			triggerErrorModal(error);
		},
		retry: false,
		staleTime: 60000,
		enabled: false,
		refetchOnWindowFocus: false,
		refetchOnMount: false,
		refetchOnReconnect: false,
	});

	return {
		getIndemnityAgreementFileUrl: refetch,
		indemnityAgreementFileUrl: data,
		indemnityAgreementFileUrlError: error,
		indemnityAgreementFileUrlLoading: isLoading,
	};
};

export const useGetAccountBondabilityLetter = (accountId: SuretyAccountId) => {
	const fileBufferQuery = useGetFileBuffer();

	const getBondabilityLetter = (companyContactId: CompanyContactId) => {
		return fileBufferQuery(
			`/v2/surety/accounts/${accountId}/bondability-letter/${companyContactId}`,
			"bondability-letter.pdf",
			["accountBondabilityLetter", accountId, companyContactId],
		);
	};

	return getBondabilityLetter;
};

export const useDeleteAccountDraft = () => {
	const queryClient = useQueryClient();
	const { _delete } = useRequest();
	const { triggerErrorModal } = useContext(GlobalErrorMessageModalContext);

	const mutation = useMutation({
		mutationFn: async (accountId: string): Promise<void> => {
			queryClient.setQueryData<SuretyAccountPreview[]>(["accountPreviews"], (old) => {
				if (!old) throw new Error();
				const index = old.findIndex((preview) => preview.id === accountId);
				if (index === -1) return old;
				return [...old.slice(0, index), ...old.slice(index + 1)];
			});

			await _delete(`/v2/surety/accounts/draft/${accountId}`, undefined, "none");
		},
		onError: (error: ApiError) => {
			queryClient.refetchQueries({ queryKey: ["accountPreviews"] });
			triggerErrorModal(error);
		},
	});

	return { deleteAccountDraft: mutation.mutate, deleteAccountDraftIsLoading: mutation.isLoading };
};

export const useArchiveAccount = () => {
	const queryClient = useQueryClient();
	const { post } = useRequest();
	const { triggerErrorModal } = useContext(GlobalErrorMessageModalContext);

	const mutation = useMutation({
		mutationFn: async (suretyAccountId: string): Promise<void> => {
			await post(`/v2/surety/accounts/${suretyAccountId}/archive`, undefined, "none");
		},
		onMutate: (suretyAccountId: string) => {
			queryClient.setQueryData<SuretyAccountPreview[]>(["accountPreviews"], (old) => {
				if (!old) throw new Error();
				const index = old.findIndex((preview) => preview.id === suretyAccountId);
				const newPreviews = [...old];
				newPreviews[index] = { ...newPreviews[index], archived: true };
				return newPreviews;
			});
		},
		onError: (error: ApiError) => {
			queryClient.refetchQueries({ queryKey: ["accountPreviews"] });
			triggerErrorModal(error);
		},
	});

	return { archiveAccount: mutation.mutate, archiveAccountLoading: mutation.isLoading };
};

export const useUnarchiveAccount = () => {
	const queryClient = useQueryClient();
	const { post } = useRequest();
	const { triggerErrorModal } = useContext(GlobalErrorMessageModalContext);

	const mutation = useMutation({
		mutationFn: async (suretyAccountId: string): Promise<void> => {
			await post(`/v2/surety/accounts/${suretyAccountId}/unarchive`, undefined, "none");
		},
		onMutate: (suretyAccountId: string) => {
			queryClient.setQueryData<SuretyAccountPreview[]>(["accountPreviews"], (old) => {
				if (!old) throw new Error();
				const index = old.findIndex((preview) => preview.id === suretyAccountId);
				const newPreviews = [...old];
				newPreviews[index] = { ...newPreviews[index], archived: false };
				return newPreviews;
			});
		},
		onError: (error: ApiError) => {
			queryClient.refetchQueries({ queryKey: ["accountPreviews"] });
			triggerErrorModal(error);
		},
	});

	return { unarchiveAccount: mutation.mutate, unarchiveAccountLoading: mutation.isLoading };
};
